import React from "react";
import { Helmet } from "react-helmet";
import "../styles/index.css";
import { Link } from "gatsby";
import Navbar from "../components/navbar";

export default function custom404() {
  return (
    <main>
      <Helmet>
        <title>Impressum | Nina Luciak</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Praxis Nina Luciak" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Navbar />

      <span className="text-green-700 block text-center text-4xl">404</span>

      <Link to="/" className="text-blue-400 m-4 block text-center">
        Zurück
      </Link>
    </main>
  );
}
